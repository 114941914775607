<template>
  <app-module-view>
    <template slot="body">
      <app-feUser-filter v-if="!feUserId"></app-feUser-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="feUserPollVotes"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../ModuleView'
import { mapState } from 'vuex'
import config from '../../../config'
import Preloader from '../../preloader/Preloader'
import FeUserPollVoteFilter from './FeUserPollVoteFilter'
import DataTable from '../../shared/DataTable'

export default {
  name: 'FeUserPollVoteList',
  props: {
    feUserId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      page: 1
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    feUserPollVotes () {
      return this.$store.getters['feUserPollVote/list']
    },
    totalCount () {
      return this.$store.getters['feUserPollVote/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['feUserPollVote/totalCount'] / config.defaults.list.limit)
    },
    dataTableConfig () {
      return {
        parent: this,
        fields: {
          id: this.$t('feUser.id'),
          poll: this.$t('feUser.poll'),
          pollOption: this.$t('feUser.poll_option'),
          feUserExpanded: this.$t('feUser.user_email'),
          createdAt: this.$t('feUser.created_at')
        }
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appFeUserFilter: FeUserPollVoteFilter,
    appDataTable: DataTable
  },
  methods: {
    getFeUserPollVotes () {
      if (this.feUserId > 0) {
        this.$store.commit('feUserPollVote/setFilter', { feUser: this.feUserId })
      } else {
        this.$store.commit('feUserPollVote/setFilter', {})
      }
      this.$store.dispatch('feUserPollVote/fetch')
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('feUserPollVote/setPage', page)
      this.getFeUserPollVotes()
    }
  },
  mounted () {
    this.page = this.$store.getters['feUserPollVote/page']
    this.getFeUserPollVotes()
  }
}
</script>

<style lang="scss" scoped>

</style>
